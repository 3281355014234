export const BASE_URL = "https://geo.gdh.kz/oldgeo";
export const BASE_URL_IMG = "https://geo.gdh.kz/oldgeo";

export const BASE_URL_OFFICE = "https://geo.gdh.kz/newgeo";

export const BASE_URL_ANALIZ = "https://geo.gdh.kz/analizapi";
export const BASE_URL_ANALIZ_IMG = "https://geo.gdh.kz/analiz";

export const BASE_URL_NUSKA = "https://geo.gdh.kz/nuska/api";
export const BASE_URL_NUSKA_IMG = "https://geo.gdh.kz/nuska";
