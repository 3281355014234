import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./redux/store";
import { MathJaxContext } from "better-react-mathjax";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const rootEl = document.getElementById("root");
if (!rootEl) throw new Error("Failed to find the root element");

const root = ReactDOM.createRoot(rootEl && rootEl);
root.render(
   <Provider store={store}>
      {/* <React.StrictMode> */}
      <MathJaxContext>
         <App />
      </MathJaxContext>
      {/* </React.StrictMode> */}
   </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
