import React from "react";
import { AppModal } from "./AppModal";
import { IModalOptions } from "./ModalOptions";

export const LeaveTestModal = () => {
   const options: IModalOptions = {
      btnId: "open_leaveTest",
      modalId: "leaveTestModal",
      modalTitleId: "leaveTestModalLabel",
      modalTitle: "Ескерту!",
      modalBody: (
         <>
            <p>Сіз басқа бетке өтіп кеткеніңіз үшін тест автоматты түрде аяқталды!</p>
         </>
      ),
      btnCloseClick: () => {},
      btnFooter: (
         <button type="button" className="btn btn-secondary" data-dismiss="modal">
            OK
         </button>
      ),
   };

   return <AppModal {...options} />;
};
