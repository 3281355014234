export const testTimes = [
   {
      panId: 4, // Qaz. Tarih
      minutes: 20,
   },
   {
      panId: 6,
      minutes: 40,
   },
   {
      panId: 7,
      minutes: 40,
   },
   {
      panId: 555, // five
      minutes: 4 * 60,
   },
   {
      panId: 55555, // nuska
      minutes: 3 * 60,
   },
   {
      panId: 34, // teacher
      minutes: 60,
   },

   {
      panId: 17, // Информатика
      minutes: 20,
   },
   {
      panId: 8, // Мат
      minutes: 30,
   },
   {
      panId: 9, // Физ
      minutes: 30,
   },
   {
      panId: 3, // Хим
      minutes: 30,
   },
];
