// This app uses Themify Icons (https://themify.me/themify-icons)

export const sidebarLinks = [
   {
      href: "/Account",
      icon: "ti-user",
      // icon: "ti-home",
      text: "Жеке Кабинет",
   },
   {
      href: "/TestWeek",
      icon: "ti-stats-up",
      text: "Тест – Апталық",
   },
   {
      href: "/NuskaList",
      icon: "ti-check-box",
      text: "Тест - Нұсқалар",
      isNuska: true,
   },
];
