export const REQUEST_STATUSES = [
   { key: 100, value: "Успешно зарегистрировано" },
   { key: 101, value: "Бұл пошта жүйеге тіркеліп қойған" },
   { key: 102, value: "Ошибка в пароле. Длина пароля должен превышать 5 символов" },
   { key: 103, value: "Успешно авторизовано" },
   { key: 104, value: "Логин немесе құпиясөз қате!" },
   { key: 105, value: "Данный эл почта не зарегистрировано" },
   { key: 106, value: "User не найдено. Ошибка в UserId" },
   {
      key: 107,
      value: "Сіздің аккаунтіңіз бірнеше рет қолданылған. Жүйеге кіру үшін мына нөмірге жазыңыз 8 747 398 7068",
   },

   { key: 200, value: "Success" },
   { key: 201, value: "User не имеет доступ" },

   { key: 301, value: "Бір пәндік тест тапсыру лимиті бітті" },
   { key: 302, value: "5 пәндік тест тапсыру лимиті бітті" },
];
