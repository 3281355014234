export const authScriptUrls = [
   "/assets/js/jquery.min.js",
   "/assets/vendors/bootstrap/js/bootstrap.min.js",
   "/assets/vendors/bootstrap/js/popper.min.js",
   "/assets/vendors/bootstrap-select/bootstrap-select.min.js",
   "/assets/vendors/bootstrap-touchspin/jquery.bootstrap-touchspin.js",
   "/assets/vendors/magnific-popup/magnific-popup.js",
   "/assets/vendors/counter/waypoints-min.js",
   "/assets/vendors/counter/counterup.min.js",
   "/assets/vendors/imagesloaded/imagesloaded.js",
   "/assets/vendors/masonry/masonry.js",
   "/assets/vendors/masonry/filter.js",
   "/assets/vendors/owl-carousel/owl.carousel.js",
   "/assets/js/functions.js",
   "/assets/js/contact.js",
];
export const contentScriptUrls = [
   "/assets/js/jquery.min.js",
   "/assets/vendors/bootstrap/js/bootstrap.min.js",
   "/assets/vendors/counter/waypoints-min.js",
   "/assets/vendors/counter/counterup.min.js",
   "/assets/vendors/scroll/scrollbar.min.js",
   "/assets/vendors/calendar/moment.min.js",
   "/assets/vendors/bootstrap/js/popper.min.js",
   "/assets/js/admin.js",
   "/assets/js/selectScript.js",
];
export const playerScriptUrls = ["https://cdn.plyr.io/3.5.6/plyr.js", "/assets/js/playerScript.js"];
export const mathScripts = [
   "https://polyfill.io/v3/polyfill.min.js?features=es6",
   "https://cdn.jsdelivr.net/npm/mathjax@3/es5/mml-chtml.js",
];
